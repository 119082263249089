define("discourse/plugins/discourse-post-voting/discourse/components/post-voting-comment-actions", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/service", "discourse/lib/ajax", "discourse/lib/ajax-error", "I18n", "@ember/template-factory"], function (_exports, _component, _component2, _object, _service, _ajax, _ajaxError, _I18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <span class="post-voting-comment-actions">
    <DButton
      @display="link"
      @class="post-voting-comment-actions-edit-link"
      @action={{@updateComment}}
      @icon="pencil-alt"
    />
    <DButton
      @display="link"
      @class="post-voting-comment-actions-delete-link"
      @action={{this.deleteConfirm}}
      @icon="far-trash-alt"
    />
  </span>
  */
  {
    "id": "fLyv0bv0",
    "block": "[[[10,1],[14,0,\"post-voting-comment-actions\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@display\",\"@class\",\"@action\",\"@icon\"],[\"link\",\"post-voting-comment-actions-edit-link\",[30,1],\"pencil-alt\"]],null],[1,\"\\n  \"],[8,[39,0],null,[[\"@display\",\"@class\",\"@action\",\"@icon\"],[\"link\",\"post-voting-comment-actions-delete-link\",[30,0,[\"deleteConfirm\"]],\"far-trash-alt\"]],null],[1,\"\\n\"],[13]],[\"@updateComment\"],false,[\"d-button\"]]",
    "moduleName": "discourse/plugins/discourse-post-voting/discourse/components/post-voting-comment-actions.hbs",
    "isStrictMode": false
  });
  class PostVotingCommentActions extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "dialog", [_service.inject]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    deleteConfirm() {
      this.dialog.deleteConfirm({
        message: _I18n.default.t("post_voting.post.post_voting_comment.delete.confirm"),
        didConfirm: () => {
          const data = {
            comment_id: this.args.id
          };
          (0, _ajax.ajax)("/post_voting/comments", {
            type: "DELETE",
            data
          }).then(() => {
            this.args.removeComment(this.args.id);
          }).catch(_ajaxError.popupAjaxError);
        }
      });
    }
    static #_2 = (() => dt7948.n(this.prototype, "deleteConfirm", [_object.action]))();
  }
  _exports.default = PostVotingCommentActions;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PostVotingCommentActions);
});